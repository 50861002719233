@import "../../../assets/utils/vars.scss";

.no-comments {
  color: $textSecondary;
  font-size: 14px;
  opacity: 0.65;
  padding: 20px;
}

.comments-column {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;

  .comment-wrapper {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .comment-element {
    position: relative;
    left: 0;
    user-select: none;
    cursor: pointer;
    padding: 10px 15px 10px 20px;
    border-radius: 3px;
    transition: 0.2s all;
    background: #fff;

    &:hover {
      border-left-width: 6px !important;
    }

    &.showing {
      border-left-width: 6px !important;

      .comment {
        padding-bottom: 10px;
      }

      .ce-lower {
        // max-height: 100px;
        max-height: fit-content;
      }
    }

    &.complete {
      border-left: 4px solid $success;
    }

    &.pending {
      border-left: 4px solid $warning;
    }

    &.declined {
      border-left: 4px solid $muted;
    }

    &.active {
      border-left: 4px solid $info;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .ce-name {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.2em;
      color: #80889d;

      p {
        margin-right: auto;
      }

      .vertical-menu-toggle {
        padding: 4px 10px;
        transition: 0.2s all;
        border-radius: 2px;

        &:hover {
          background: $infoBG;

          svg {
            path {
              fill: $infoFG;
            }
          }
        }

        svg {
          height: 10px;
          width: 2.2px;

          path {
            transition: 0.2s all;
            fill: #80889d;
          }
        }
      }

      .vertical-menu {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 100;
        transform: scale(0.7);
        top: 34px;
        right: 12px;
        transition: 0.2s all;
        background: #fff;
        border-radius: 3px;
        padding: 3px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        backface-visibility: none;
        transform-style: preserve-3d;

        &.is-showing {
          opacity: 1;
          top: 28px;
          transform: scale(1);
          pointer-events: all;
        }

        p {
          color: $inputPlaceholder;
          font-size: 12px;
          font-weight: 400;
          transition: 0.2s all;
          padding: 3px 10px;

          &:hover {
            color: #fff;
            background: $infoFG;
          }

          &:first-child {
            border-radius: 3px 3px 0 0;
          }

          &:last-child {
            border-radius: 0 0 3px 3px;
          }

          &.delete-comment {
            &:hover {
              background: $negativeFG;
            }
          }
        }
      }

      span {
        margin-left: 5px;
        font-weight: 500;
        font-size: 12px;
        color: rgba(128, 136, 157, 0.6);
        line-height: 1.2em;
      }

      svg {
        height: 5px;
        width: auto;

        path {
          fill: #80889d;
        }
      }
    }

    .ce-comment {
      margin-bottom: 5px;

      p {
        font-size: 15px;
        line-height: 1.2em;
      }
    }

    .ce-lower {
      box-sizing: border-box;
      position: relative;
      max-height: 0;
      overflow: hidden;
      transition: 0.2s all ease-in-out;
      padding-top: 0;

      .ce-meta {
        margin: 8px 0 5px;

        p {
          color: #80889d;
          font-size: 12px;
          line-height: 1.25em;
        }
      }

      .comment-reply-wrapper {
        margin-top: 10px;

        button {
          width: 100%;
          margin: 5px 0;
          font-size: 13px;
          padding: 9px;
        }
      }
    }
  }
}
