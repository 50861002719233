@import "../../../assets/utils/vars.scss";

.content-feedback-tools {
  position: absolute;
  z-index: 1005;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 18px 10px;
  background: #fff;
  border-radius: 4px;

  .content-feedback-tool-buttons {
    margin-bottom: 10px;
  }

  .feedback-tool-button {
    position: relative;
    height: 26px;
    width: 26px;
    background: #fff;
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;

    svg {
      max-height: 14px;
      max-width: 14px;

      path {
        fill: $infoFG;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }

    &:hover,
    &.active {
      background: $infoBG;
    }
  }

  .content-feedback-tool {
    position: relative;
    height: 26px;
    width: 26px;
    background: #fff;
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;

    svg {
      max-height: 14px;
      max-width: 14px;

      path {
        fill: $infoFG;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }

    &:hover,
    &.active {
      background: $infoBG;
    }
  }

  .brush-size-circle {
    position: relative;
    height: 26px;
    width: 26px;
    background: #fff;
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;

    &.small {
      svg {
        transform: scale(0.4);
      }
    }

    &.medium {
      svg {
        transform: scale(0.7);
      }
    }

    &.large {
      svg {
        transform: scale(1);
      }
    }

    svg {
      max-height: 14px;
      max-width: 14px;

      path {
        fill: $infoFG;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }

    &:hover,
    &.active {
      background: $infoBG;
    }
  }
  .content-feedback-tool-brush-size {
    margin-top: 15px;
  }

  .content-feedback-tool-extras {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid $inputBorder;
    margin-top: 15px;
    padding-top: 15px;

    .content-feedback-tool-colours {
      display: flex;
      align-content: center;
      justify-content: center;

      .hue-vertical {
        & > div {
          & > div {
            cursor: pointer;
            height: 21px !important;
            width: 21px !important;
          }
        }
      }
    }
  }

  .feedback-comment-wrapper {
    position: relative;
  }
}
