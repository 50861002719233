.clients-display {
  display: flex;
  flex-wrap: wrap;

  &.CARD {
  }

  &.ROW {
    flex-direction: column;
  }
}
