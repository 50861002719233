.error-page-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 30px;
    text-align: center;

    span {
      display: block;
      font-size: 180px;
      font-weight: 700;
    }
  }
}
