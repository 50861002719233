.notification {
  display: flex;
  align-items: center;
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }

  .notif-info {
    width: 620px;

    h1 {
      font-size: 15px;
    }

    p {
      font-size: 14px;
      margin: 4px 0 0;
    }
  }

  .notif-toggle-wrap {
    margin-right: auto;
  }
}
