@import "../../../../assets/utils/vars.scss";

.userli {
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(189, 189, 189, 0.45);
  margin-left: 0;
  transition: 0.2s all;
  user-select: none;
  overflow: hidden;
  border-left: 4px solid #fff;

  &:hover {
    margin-left: 10px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.open {
    border-left: 4px solid $info;

    .userli-primary {
      border-bottom: 1px solid #d9d9d9;
    }

    .userli-secondary {
      max-height: 999px;
    }
  }

  .userli-primary {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #fff;
    transition: 0.2s all;
    cursor: pointer;

    p {
      font-size: 14px;
    }

    .userli-image {
      position: relative;
      margin-right: 12px;
      flex: 0 0 50px;

      .userli-image-wrap {
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: #f3f3f3;

        img {
          position: relative;
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .profile-initials {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          color: $inputPlaceholder;
          line-height: 1em;
          font-weight: 500;
        }
      }
    }

    .userli-status {
      margin-right: 22px;
      flex: 0 0 45px;

      .pill {
        text-transform: uppercase;
      }

      .content-loading {
        width: 100%;
      }
    }

    .userli-name {
      margin-right: 12px;
      flex: 0 0 200px;
    }

    .userli-email {
      margin-right: 12px;
      flex: 0 0 320px;
    }

    .userli-permissions {
      flex: 1 1;
    }

    .userli-premium {
      p {
        display: flex;
        align-items: center;

        small {
          font-size: 10px;
          padding: 5px 12px;
          border-radius: 3px;
          margin-right: 8px;
          color: #3d4eac;
          background: #d6ecff;
          line-height: 1em;
        }
      }
    }

    .userli-remove {
      display: block;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      color: #80889d;
      transition: 0.2s all;
      border-radius: 2px;

      &:hover {
        color: #ea4444;
        background: rgba(234, 68, 68, 0.1);
      }

      svg {
        pointer-events: none;
      }
    }

    .userli-chev {
      margin-left: auto;
    }
  }

  .userli-secondary {
    max-height: 0;
    overflow: hidden;
    max-width: 920px;
    transition: 0.2s all;

    h1 {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
    }

    .userli-heading {
      padding: 12px 24px;
      border-bottom: 1px solid #d9d9d9;

      &:not(:first-child) {
        border-top: 1px solid #d9d9d9;
      }
    }

    .userli-permissions-block {
      .userli-permission {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        max-width: 645px;

        &:not(:last-child) {
          border-bottom: 1px solid #f2f2f2;
        }

        .userli-permission-title {
          .cc-head {
            h1 {
              color: $textPrimary;
              font-size: 15px;
              font-weight: 500;
              line-height: 1.2em;
              margin: 0;
            }

            p {
              color: $textSecondary;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2em;
              margin: 4px 0 0;
            }
          }
        }

        .userli-permission-toggle {
          margin-left: auto;
        }
      }
    }

    .userli-projects-block {
      .no-projects {
        color: #80889d;
        font-size: 14px;
        padding: 0 24px;
        line-height: 1em;
        margin: 14px 0;
        display: block;
        opacity: 0.7;
      }

      .userli-project {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        max-width: 645px;

        &:first-child {
          padding: 12px 24px 0;

          p {
            color: #80889d;
            font-size: 12px;
            font-weight: 500;
            line-height: 1em;
            margin: 0;
          }
        }

        .userli-project-toggles {
          display: grid;
          grid-gap: 0;
          grid-template-columns: repeat(2, 104px);
          margin-left: auto;
        }
      }
    }
  }
}

.user-list-item {
  position: relative;
  background: #fff;
  padding: 12px 20px 12px 16px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(189, 189, 189, 0.45);
  margin-left: 0;
  transition: 0.2s all;
  user-select: none;
  overflow: hidden;
  border-left: 4px solid #fff;

  &.header {
    display: flex;
    align-items: center;
    box-shadow: none;
    background: transparent;
    border: none;
    cursor: default;
    margin-bottom: 0 !important;

    &:hover {
      margin-left: 0;
    }

    .uli {
      p {
        color: #80889d;
        font-size: 14px;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    margin-left: 10px;
  }

  .uli-primary {
    display: flex;
    align-items: center;
  }

  .uli-secondary {
    max-height: 0;
    opacity: 0;
    transition: 0.2s;
    margin-top: 0;
    cursor: default;
    display: flex;
    flex-direction: column;

    .uli-projects {
      display: flex;
      align-items: center;

      h2 {
        font-size: 15px;
        margin-right: 10px;
      }
    }

    .uli-actions {
      margin-top: 4px;

      .uli {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .uli-unassign {
      position: relative;
      background: #fff;
      cursor: pointer;
      margin-top: 8px;
      display: inline-block;
      width: fit-content;

      p {
        font-size: 14px;
        padding: 5px 15px;
        color: #80889d;
        border-radius: 4px;
        transition: 0.2s all;
        border: 1px solid #cbd9e6;

        &:hover {
          color: #4486ea;
          background: rgba(68, 134, 234, 0.1);

          &.danger {
            color: #ea4444;
            border: 1px solid #e9b0b0;
            background: rgba(234, 68, 68, 0.1);
          }
        }
      }
    }
  }

  &.editing {
    border-left: 4px solid $info;

    .uli-secondary {
      max-height: 500px;
      opacity: 1;
      margin-top: 15px;
    }
  }

  .uli-image {
    width: 57px;
  }

  .uli-pill {
    width: 65px;
  }

  .uli-name {
    width: 190px;
  }

  .uli-email {
    width: 290px;
  }

  .uli-added {
    width: 140px;
  }

  .uli-action {
    margin-left: auto;
    font-size: 14px;
  }

  .uli-image {
    .uli-image-wrap {
      position: relative;
      height: 42px;
      width: 42px;
      border-radius: 100%;
      overflow: hidden;
      background: #ddd;

      img {
        display: block;
        position: relative;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }
}

@media (max-width: 1450px) {
  .userli {
    .userli-primary {
      .userli-name {
        flex: 0 0 165px;
      }

      .userli-email {
        flex: 0 0 255px;
      }
    }
  }
}
