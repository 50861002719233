@import "../../../assets/utils/vars.scss";

.content-upload-form {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(189, 189, 189, 0.45);
  margin-bottom: 40px;
  padding: 20px;

  h1 {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 12px;

    & > div {
      display: inline;
      margin-left: auto;
    }

    svg {
      height: 12px;
      margin-top: -5px;
      cursor: pointer;
      transition: 0.2s all;

      path {
        fill: $negativeFG;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .upload-form-inputs {
    display: grid;
    grid-gap: 10px;

    .file-drop {
      .file-drop-target {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #f9f9f9;
        border: 1px dashed #dddddd;
        border-radius: 4px;
        min-height: 180px;
        width: 100%;
        max-width: 450px;
        transition: 0.2s all;
        padding: 10px;
        cursor: pointer;

        .file-drop-placeholder {
          margin: auto;

          h1 {
            display: block;
            color: #80889d;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 8px;
          }

          h2 {
            color: #80889d;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
          }
        }

        &:hover,
        &.has-files,
        &.file-drop-dragging-over-frame {
          border: 1px dashed $infoFG;
        }
      }
    }

    .file-row {
      width: 100%;
      background: #fff;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }

      .content {
        display: flex;
        align-items: center;
        padding: 7px 11px;

        p {
          color: $darkfont;
          font-size: 14px;
          font-weight: 400;
          margin-right: auto;
        }

        #remove-file {
          cursor: pointer;

          &:hover {
            svg {
              path {
                fill: $negativeFG;
              }
            }
          }

          svg {
            height: 12px;
            pointer-events: none;

            path {
              fill: $textSecondary;
              transition: 0.2s all;
            }
          }
        }
      }

      .progress {
        height: 14px;
        max-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: 0.2s all;
        overflow: hidden;
        background: $infoFG;

        &.done {
          max-height: 3px;
          background: $positiveFG;

          small {
            opacity: 0;
          }
        }

        small {
          color: #fff;
          font-size: 10px;
          font-weight: 400;
          opacity: 1;
          transition: 0.2s all;
        }
      }

      &.error {
        .progress {
          background: $negativeFG;
        }
      }
    }
  }
}
