@import "../../../assets/utils/vars.scss";

.recent-project-folders {
  display: flex;
}

.recent-project {
  position: relative;
  display: block;
  left: -25px;
  opacity: 0;
  height: 275px;
  cursor: pointer;
  transition: 0.2s all;
  animation: slideIn 0.5s ease-in-out forwards;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      left: -25px;
    }

    100% {
      opacity: 1;
      left: -0;
    }
  }

  svg {
    height: 100%;

    path {
      transition: 0.2s all;
      fill: #fff !important;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    }
  }

  &:hover {
    color: #fff;

    .project-details {
      color: #fff;
    }

    svg {
      path {
        fill: $infoFG !important;
        box-shadow: 0 3px 20px rgba(0, 136, 255, 0.5);
      }
    }
  }

  .project-details {
    position: absolute;
    top: 56%;
    width: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
      margin-top: 6px;

      span {
        font-size: 12px;
        opacity: 0.6;
        font-weight: 400;
        margin-right: 3px;
      }
    }

    .project-users {
      margin-top: 45px;

      p {
        font-size: 12px;
        text-transform: uppercase;
      }

      .project-user-images {
        display: flex;
        margin-top: 10px;
        min-height: 40px;

        .recent-project-user {
          position: relative;
          display: block;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 5px;
          background: $infoFG;

          img {
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .profile-initials {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
