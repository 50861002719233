@import "../../../../../assets/utils/vars.scss";

.replies-column {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 28px;

  &.complete {
    &:after {
      background: $positiveFG;
    }

    .comment-element {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:before {
        background: $positiveFG;
      }

      &:after {
        background: $positiveFG;
      }
    }
  }

  &:after {
    content: "";
    width: 2px;
    height: 10px;
    background: $infoFG;
    position: absolute;
    left: -14px;
    top: -10px;
  }

  .comment-element {
    &:not(:last-child) {
      margin-bottom: 10px;

      .connecting-line {
        content: "";
        width: 2px;
        height: 75%;
        background: $infoFG;
        position: absolute;
        left: -14px;
        bottom: -60%;
        transform: translateY(-50%);
      }
    }

    &:before {
      content: "";
      width: 2px;
      height: 50%;
      background: $infoFG;
      position: absolute;
      left: -14px;
      top: 0;
    }

    &:after {
      content: "";
      height: 2px;
      width: 14px;
      background: $infoFG;
      position: absolute;
      left: -14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
