.client-view-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .client-logo {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 8px;
        background: #ddd;

        img {
            display: block;
            position: relative;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.client-display {
    flex: 1 1 auto;
    overflow-y: auto;

    .client-section-heading {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        button {
            margin-left: 20px;
        }
    }
}

.view-client-nav {
    position: relative;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #ddd;
    }

    a {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #80889d;
        font-weight: 500;
        line-height: 1.2em;
        padding: 10px 0;
        border-bottom: 2px solid #ddd;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &:hover,
        &.active {
            color: #4486ea;
            border-bottom: 2px solid #4486ea;

            .nav-item-icon {
                svg {
                    color: #4486ea;
                }
            }
        }
    }
}