@import "../../assets/utils/vars.scss";

.project-card {
  position: relative;
  width: 300px;
  height: 305px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 20px;
  box-shadow: 0px 5px 10px 0px rgba(189, 189, 189, 0.45);
  margin: 0 35px 35px 0;
  user-select: none;

  .project-collage {
    position: relative;
    background: #ddd;
    height: 110px;
    border-radius: 5px 5px 0 0;
    margin-bottom: 30px;
    overflow: hidden;

    img {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .toggle-project-options {
      position: absolute;
      z-index: 2;
      top: 5px;
      right: 5px;
      background: #fff;
      border: 1px solid #cbd9e6;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0px 3px 5px 0px rgba(189, 189, 189, 0.45);

      .project-options-inner {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        transition: 0.2s all;

        svg {
          transition: 0.2s all;
          color: #cbd9e6;
        }

        .pencil {
          font-size: 17px;
        }

        .chev-up,
        .chev-down {
          font-size: 15px;
          margin-left: 8px;
        }

        .chev-up {
          display: none;
        }

        &:hover {
          color: #4486ea;
          background: rgba(68, 134, 234, 0.1);

          svg {
            color: #4486ea;
          }
        }

        &.toggled {
          color: #4486ea;
          background: rgba(68, 134, 234, 0.1);

          svg {
            color: #4486ea;
          }

          .chev-down {
            display: none;
          }

          .chev-up {
            display: block;
          }
        }
      }

      .edit-dropdown {
        position: absolute;
        right: 0;
        opacity: 0;
        top: 20px;
        pointer-events: none;
        background: #fff;
        border: 1px solid #cbd9e6;
        padding: 5px;
        border-radius: 4px;
        box-shadow: 0px 3px 5px 0px rgba(189, 189, 189, 0.45);
        transition: 0.2s all;

        &.toggled {
          opacity: 1;
          top: 32px;
          pointer-events: all;
        }

        p {
          font-size: 14px;
          padding: 5px 15px;
          color: #80889d;
          border-radius: 4px;
          transition: 0.2s all;

          &:hover {
            &.danger {
              color: #ea4444;
              background: rgba(234, 68, 68, 0.1);
            }
          }
        }
      }
    }
  }

  .project-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 1em;
    text-align: center;
    margin: 0 0 12px;
  }

  .project-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 92%;
    margin: 18px auto 20px;

    .stat {
      p {
        font-size: 14px;
        font-weight: 600;
        text-align: center;

        span {
          display: block;
          font-size: 13px;
          font-weight: 400;
          margin-top: 2px;
          color: $inputPlaceholder;
        }
      }
    }
  }

  .project-manage {
    display: flex;
    justify-content: center;

    .ui-button {
      width: 200px;
      justify-content: center;
    }
  }
}
