// Google fonts import for Rubik
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

// Global style variables
@import "./assets/utils/vars.scss";

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Rubik", sans-serif;
  color: #333333;
  font-weight: 500;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  font-weight: 700;
  color: #0072c6;
  transition: 0.2s all;

  &:hover {
    color: darken(#0072c6, 8%);
  }
}

p {
  line-height: 1.4em;
}

#app-screen-wrapper {
  display: flex;
  position: relative;
  background: #fff;

  #app-screen-height-contain {
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: relative;

    #app-screen-inner {
      display: flex;
      position: relative;
      margin: 20px 20px 0 0;
      padding: 20px 20px 0 20px;
      background: $tileBG;
      border-radius: 25px 25px 0 0;
      height: calc(100% - 20px);
    }
  }
}

.grid {
  display: grid;
  grid-gap: 12px;

  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .span-2-hori {
    grid-column-end: span 2;
  }
}

.image-collage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-radius: 7px 7px 0 0;
  overflow: hidden;

  .collage-row {
    display: flex;
    align-items: center;
    flex: 1 1;
    width: 100%;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  .image-collage-item {
    position: relative;
    flex: 1 1;
    height: 100%;

    &:not(:last-child) {
      margin-right: 2px;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.left-align {
  text-align: left;
}

.cards-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.display-title {
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    position: relative;
    color: #333333;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -5px;
      background: #000;
    }
  }

  h1 {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .splitter {
    font-size: 22px;
    font-weight: 600;
    margin: 0 20px;
  }

  .action-buttons {
    display: flex;
    margin-left: auto;

    button {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.red {
  color: $negativeFG;
}

.green {
  color: $positiveFG;
}

@media (max-width: 980px) {
  .display-title {
    h1 {
      font-size: 19px;
    }

    .splitter {
      font-size: 16px;
      margin: 0 12px;
    }
  }
}

.content-loading {
  position: relative;
  background: #f1f1f1;
  overflow: hidden;

  &.text {
    border-radius: 2px;
    height: 10px;
    width: 175px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 25px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: loading 1s ease-in-out infinite;
  }
}

@keyframes loading {
  0% {
    left: -25px;
  }

  100% {
    left: calc(100% + 25px);
  }
}

#toast-alerts {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

@media (max-width: 500px) {
  #app-splash {
    flex-direction: column;
  }
}
