@import "../../../../assets/utils/vars.scss";

.userli-chev {
  svg {
    path {
      fill: #333;
    }
  }
}

.premium-stars {
  position: absolute;
  top: -2px;
  right: 3px;

  svg {
    path {
      fill: #ffd700;
    }
  }
}

.userli-remove {
  &:hover {
    svg {
      path {
        fill: $negativeFG;
      }
    }
  }

  svg {
    path {
      fill: #8c8b97;
      transition: 0.2s all;
    }
  }
}
