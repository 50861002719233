@import "../../../assets/utils/vars.scss";

.edit-details-block {
  max-width: 775px;

  &:not(:last-of-type) {
    padding-bottom: 25px;
    border-bottom: 1px solid $disabledBG;
    margin-bottom: 25px;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 16px;
    color: $inputPlaceholder;

    strong {
      font-weight: 600;
    }
  }

  .ui-input-wrapper {
    max-width: 300px;
    margin-bottom: 14px;
  }

  .password-input-wrapper {
    position: relative;
    max-width: 300px;

    .password-view-toggle {
      position: absolute;
      z-index: 2;
      right: 15px;
      top: 0;
      cursor: pointer;
      user-select: none;
      height: 42px;
      display: flex;
      align-items: center;

      svg {
        path {
          fill: $inputPlaceholder;
        }
      }
    }
  }
}
