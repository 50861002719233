@import "../../assets/utils/vars.scss";

.dashboard-invites {
  margin-bottom: 30px;
}

.dashboard-invite-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $primary;
  max-width: 775px;
  padding: 0 8px 0 16px;
  border-radius: 10px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 0;
  }

  .invite-actions {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: none;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: 0.2s all;

      &.accept {
        background: #fff;
        padding: 4px 10px;
        border-radius: 4px;
        color: $infoFG;
      }

      &.ignore {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.85);

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
