@import "../../../assets/utils/vars.scss";

.add-project-form {
  position: relative;
  width: 300px;
  height: 305px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 20px;
  box-shadow: 0px 5px 10px 0px rgba(189, 189, 189, 0.45);
  margin: 0 35px 35px 0;
  user-select: none;
  border: 2px solid #fff;
  transition: 0.2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.is-active,
  &:hover {
    border: 2px solid $infoFG;

    .add-toggle {
      svg {
        path {
          fill: $infoFG;
        }
      }
    }
  }

  .add-toggle {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 65px;
      width: 65px;

      path {
        fill: $inputPlaceholder;
        transition: 0.2s all;
      }
    }
  }

  .add-inputs {
    width: 100%;

    .ui-select-wrapper,
    .ui-input-wrapper {
      width: 90%;
      margin: 0 auto 10px;
      margin-bottom: 10px;
    }

    button {
      width: 90%;
      margin: auto;

      &:not(:last-of-type) {
        margin: 0 auto 5px;
      }
    }
  }
}
