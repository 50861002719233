@import '../../../assets/utils/vars.scss';

#comments-overlay {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.tool-active {
        z-index: 5;
    }

    .cview-comment {
        position: absolute;
        transform: translate(-50%, -50%);

        .cview-comment-inner {
            height: 25px;
            width: 25px;
            background: rgba(255, 255, 255, 0.75);
            border-radius: 100%;
            border: 3px solid #fff;
            opacity: 0.75;
            transition: 0.2s all;
        }

        &.toggled {
            .cview-comment-inner {
                opacity: 1;
                transform: scale(1.75);
                background: rgba(42, 102, 191, 0.75);
                border: 2px solid $info;
                animation: commentFocus 0.3s ease-in-out;
            }
        }
    }
}