@import "../../../../assets/utils/vars.scss";

.tile-navigation {
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  .links {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    a {
      color: $inputPlaceholder;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 15px;
      transition: 0.2s all;
      user-select: none;
      border-bottom: 1px solid $inputBorder;

      &:not(:last-child) {
        margin-right: 40px;
      }

      &:hover,
      &.is-active {
        color: $infoFG;
        border-bottom: 1px solid $infoFG;
      }
    }
  }

  .lower-border {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border-bottom: 1px solid $inputBorder;
  }
}
