.feedback-comment-window {
  position: absolute;
  z-index: 5;
  padding: 5px;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(189, 189, 189, 0.45);
  display: flex;
  flex-direction: column;
  width: 350px;

  &.flip-x {
    left: -100%;
  }

  &.flip-y {
    top: -100%;
  }

  &.drawing {
    padding: 10px;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: "";
      position: absolute;
      left: -8px;
      top: 50%;
      width: 0;
      height: 0;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #fff;
    }
  }

  &.is-active,
  &.showing {
    opacity: 1;
    pointer-events: all;
  }

  .feedback-comment-seconds {
    margin: 7px 0 4px;
    font-size: 14px;
  }

  &.flip-x {
    transform: translateX(-100%);
  }

  &.flip-y {
    transform: translateY(-100%);
  }

  &.flip-x.flip-y {
    transform: translate(-100%, -100%);
  }

  &.adding {
    pointer-events: all;
  }

  button {
    margin-top: 5px;
  }
}
