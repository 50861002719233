#login-page {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: #f7f7f7;

  #login-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 425px;
    background: #fff;
    padding: 50px;
    text-align: center;

    #login-logo {
      width: 90%;
      margin: 0 auto 25px;

      img {
        display: block;
        width: 100%;
      }
    }

    .firebaseui-idp-button {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      max-width: 255px;
    }

    #login-disclaimer {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      opacity: 0.8;
      letter-spacing: -0.4px;
    }
  }
}

#app-screen {
  flex: 1 1 auto;
  background: #ebecee;
  padding: 40px;
  overflow-y: auto;
}

@media (max-width: 980px) {
  #app-screen {
    padding: 25px;
  }
}

@media (max-width: 500px) {
  #login-page {
    #login-wrap {
      padding: 40px 20px;
    }
  }
}
