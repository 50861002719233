@import "../../../assets/utils/vars.scss";

.StripeElement {
  padding: 10px 18px;
  background: $inputBackground;
  border-radius: 4px;
  max-width: 400px;
  margin-bottom: 20px;
}

.billing-card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(68, 68, 68, 0.15);
  display: flex;
  align-items: center;
  padding: 15px 24px;
  width: fit-content;
  position: relative;

  .bc-removing {
    position: absolute;
    z-index: 1005;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all;

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }

    p {
      margin: 0;
      color: #333;
      font-weight: 500;
    }

    .bc-removal-spinner {
      position: relative;
      height: 12px;
      width: 12px;
      margin-left: 8px;
      transform: rotate(10deg);
      animation-name: loadingSpinner;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

      svg {
        position: absolute;
        top: 0;
        right: 0;
        height: 78%;
      }
    }
  }

  .bc-brand {
    height: 40px;
    width: 75px;
    border: 1px solid #e5e7e9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 14px;

    img {
      max-width: 45px;
    }
  }

  .bc-number {
    margin-right: 26px;

    p {
      color: #4e5c70;
      font-size: 15px;
      font-weight: 500;
      line-height: 1em;
      margin: 0 0 2px;
    }

    small {
      color: #7e838d;
      font-size: 12px;
      font-weight: 400;
      line-height: 1em;
      margin: 0;
    }
  }

  .bc-remove {
    display: flex;
    align-items: center;
    margin-left: auto;

    p {
      color: $negativeFG;
      font-weight: 500;
      margin: auto;
      cursor: pointer;
    }
  }
}

.edit-details-block {
  small {
    font-size: 14px;
    font-weight: 400;
    color: #8c8b97;
    opacity: 0.55;
  }

  .subscription-licenses {
    margin-bottom: 20px;

    p {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 2px;
      line-height: 1em;
      color: #333;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      color: #8c8b97;
      opacity: 0.75;
    }
  }

  .subscription-period-end {
    font-size: 14px;
    font-weight: 500;
    color: $negativeFG;
    margin: 0 0 10px;
  }
}

.invoice-action-buttons {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 42px;

  a {
    color: #2a66bf;
    font-size: 13px;

    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      position: relative;
      background: $primary;
      border: none;
      color: #fff;
      cursor: pointer;
      transition: 0.2s all;
      font-family: $primaryFont;
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      padding: 6px 22px;
      margin-left: 12px;

      &:hover {
        background: darken($primary, 5%);
      }
    }
  }
}

.source-addition-error {
  color: $negativeFG !important;
  margin: -10px 0 15px !important;
}

.invoices-table {
  width: calc(100% + 55px);
  margin-left: -55px;
  text-align: left;

  thead {
    tr {
      th {
        font-size: 13px;
        font-weight: 400;
        padding-bottom: 8px;
        line-height: 1em;
        color: $inputPlaceholder;

        &:first-child {
          padding-left: 55px;
        }

        &:nth-child(1) {
          width: 210px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 130px;
        }

        &:nth-child(4) {
          width: 85px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        p {
          color: #333;
          font-size: 14px;
          font-weight: 400;
          line-height: 44px;
          margin: 0;
        }

        &:first-child {
          padding-left: 55px;
        }
      }

      &:nth-child(odd) {
        background: #f8f8f8;
      }
    }
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
