@import "../../../assets/utils/vars.scss";

.invite-user-window {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0;
  transition: 0.2s all;
  user-select: none;
  margin-bottom: 15px;

  .ui-select-wrapper,
  .ui-input-wrapper {
    width: 265px;
    margin-right: 10px;
  }
}

.client-users {
  height: 100%;

  .client-section-heading {
    .add-client-user {
      position: relative;
      margin-left: 25px;
      width: 250px;
    }

    button,
    .select {
      margin-left: 10px;
    }

    .agency-user-list {
      position: absolute;
      z-index: 2;
      top: 50px;
      left: 0;
      background: #fff;
      border: 1px solid #cbd9e6;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0px 3px 5px 0px rgba(189, 189, 189, 0.45);
      padding: 5px 8px;
      align-items: center;
      transition: 0.2s all;
      min-width: 100%;
      opacity: 0;
      pointer-events: none;

      &.true,
      &:focus {
        top: 40px;
        opacity: 1;
        pointer-events: all;
      }

      h6 {
        font-size: 14px;
        padding: 4px 0;
      }

      .agency-user-item {
        p {
          font-size: 14px;
          padding: 5px 15px;
          color: #80889d;
          border-radius: 4px;
          transition: 0.2s all;

          &:hover {
            color: #4486ea;
            background: rgba(68, 134, 234, 0.1);
          }
        }
      }
    }
  }
}
