@import '../../../assets/utils/vars.scss';

.toggle {
    position: relative;
    width: 40px;
    height: 22px;
    background: #B3CFF4;
    border-radius: 23px;
    transition: 0.2s all;
    cursor: pointer;
    padding: 3px;

    &:after {
        content: '';
        position: relative;
        display: block;
        z-index: 2;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #F2F4F6;
        transition: 0.2s all;
        box-shadow: 0px 1px 2px 0px #bebebe;
        margin-left: 0;
    }

    &.on {
        background: $info;

        &:after {
            box-shadow: 0px 1px 2px 0px #424242;
            margin-left: 17px;
        }
    }
}