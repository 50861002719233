@import "../../../assets/utils/vars.scss";

.view-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 5px solid $info;
  width: 100%;
  overflow: auto;

  &.video {
    padding: 25px;

    .content-media-holder {
      margin-top: auto;

      .vc-dimensions-controller {
        position: relative;
      }
    }
  }

  &.image {
    .content-media-holder {
      margin: auto;

      .react-pdf__Document,
      .react-pdf__Page {
        position: relative;
        max-width: 100% !important;

        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .content-feedback-tile-drawings {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: none;

    .drawing-opacity-control {
      opacity: 0.2;
      transition: 0.2s all;

      &.is-active {
        opacity: 1;
      }
    }
  }
}

.view-component-loading {
  position: fixed;
  z-index: 2005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;

  img {
    width: 200px;
  }

  h1 {
    font-size: 32px;
    margin: 35px 0 15px;
  }

  p {
    text-align: center;
    font-size: 16px;
    max-width: 320px;
    font-weight: 400;
    color: $inputPlaceholder;
  }
}

.media-timeline {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 700px;
  margin-bottom: 5px;

  .timeline-wrapper {
    margin: 0 8px;
    flex: 1 1;
  }

  .timeline-timestamp {
    color: #80889d;
    font-size: 12px;
  }

  .timeline-container {
    top: -2px;
  }

  .timeline-track {
    height: 4px;
    cursor: pointer;

    &.timeline-track-0 {
      width: 100%;
      background: $info;
    }

    &.timeline-track-1 {
      background: #e8e8e8;
    }
  }

  .timeline-pin {
    height: 16px;
    width: 16px;
    background: $info;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
    outline: none !important;
    top: -7px;

    &:hover {
      transform: scale(1.25);
    }
  }

  .seek-wrapper {
    position: relative;
    width: 500px;
    height: 4px;

    .seek-progress {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      height: 4px;
      width: 40%;
      background: $info;
    }

    .seek-pin {
      position: absolute;
      z-index: 3;
      top: -6px;
      height: 16px;
      width: 16px;
      background: $info;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.2s;

      &.seeking {
        transform: scale(1.25);
      }
    }
  }
}

.media-controls {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 18px 40px;
  max-width: 925px;
  margin-top: 30px;
  margin-bottom: auto;
  background: #fff;
  border-radius: 5px;
  border-bottom: 3px solid #ebebeb;
  box-shadow: 0 2px 15px rgba(74, 79, 81, 0.2);

  .media-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      cursor: pointer;
      margin: 0 10px;

      svg {
        color: $info;
      }
    }
  }
}
