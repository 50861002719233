@import "../../../assets/utils/vars.scss";

.timeline-grid {
  position: relative;
  display: grid;
  grid-gap: 75px;
  grid-template-columns: 600px 500px;

  &.with-padding {
    padding-top: 20px;
  }

  .content-column {
    position: relative;

    &.has-content {
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 4px;
        left: 48px;
        top: 0;
        background: $infoFG;
      }
    }

    .upload-content-toggle {
      position: relative;
      z-index: 4;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 6px 0px rgba(189, 189, 189, 0.45);
      border: 2px solid #fff;
      transition: 0.2s all;
      cursor: pointer;
      margin-bottom: 50px;
      padding: 16px 28px;
      display: flex;
      align-items: center;

      &:hover {
        border: 2px solid $infoFG;

        p {
          color: $infoFG;
        }

        svg {
          path {
            fill: $infoFG;
          }
        }
      }

      p {
        color: $darkfont;
        font-size: 18px;
        transition: 0.2s all;
      }

      svg {
        height: 16px;
        width: 16px;
        margin-right: 12px;
        margin-top: -2px;

        path {
          transition: 0.2s all;
          fill: $darkfont;
        }
      }
    }
  }

  .no-content-notice {
    user-select: none;
    text-align: center;
    max-width: 450px;

    &.with-margin {
      margin: 10px 0 30px;
    }

    p {
      font-size: 20px;
      line-height: 1em;
      margin: 0 0 5px;
    }

    small {
      font-size: 15px;
      font-weight: 400;
      line-height: 1em;
      margin: 0;
      color: $textSecondary;
    }
  }

  .project-meta {
    position: relative;

    .content-card {
      position: sticky;
      top: 0;
    }

    .project-meta-table {
      td {
        font-size: 14px;
      }

      .table-title {
        padding-right: 25px;
        padding-bottom: 10px;
        color: $textSecondary;
      }
    }
  }
}
