@import "../../assets/utils/vars.scss";

#signup-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding: 40px 75px;

  .signup-logo {
    img {
      width: 265px;
    }
  }

  .signup-input-column {
    margin: 65px 0 0 75px;

    h1 {
      font-size: 38px;
      font-weight: 300;
      line-height: 1em;
      margin: 0 0 14px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin: 0;
    }

    .signup-inputs {
      margin: 40px 0 25px;
      max-width: 385px;
      display: grid;
      grid-gap: 14px;
      grid-template-columns: 1fr;

      .password-input-wrapper {
        position: relative;

        .password-view-toggle {
          position: absolute;
          z-index: 2;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          user-select: none;

          svg {
            path {
              fill: $inputPlaceholder;
            }
          }
        }
      }
    }

    button {
      margin: 18px 0 48px;
    }

    small {
      display: block;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.3em;
      max-width: 365px;

      a {
        color: #4486ea;
        font-weight: 400;
        transition: 0.2s all;

        &:hover {
          color: darken(#4486ea, 5%);
        }
      }
    }
  }

  .side-accents {
    position: absolute;
    bottom: -4px;
    right: 0;
  }
}
