@import "../../../assets/utils/vars.scss";

.content-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  max-width: 920px;
  box-shadow: 0px 2px 10px rgba(74, 79, 81, 0.1);

  &:not(:last-child) {
    margin: 0 0 30px;
  }

  &.inline {
    &:not(:last-child) {
      margin: 0;
    }
  }

  .cc-head {
    h1 {
      color: $textPrimary;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2em;
      margin: 0;
    }

    p {
      color: $textSecondary;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2em;
      margin: 4px 0 0;
    }

    &.large {
      padding: 20px 24px;
      border-bottom: 1px solid #eaeaea;

      h1 {
        color: $textPrimary;
        font-size: 22px;
        line-height: 1em;
      }
    }

    &.small {
      color: $textSecondary;
      padding: 14px 24px;
      border-bottom: 1px solid #eaeaea;

      h1 {
        color: $textSecondary;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2em;
        margin: 0;
      }
    }

    &.flex {
      display: flex;
      align-items: center;

      svg {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }

  .cc-block {
    padding: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }

    &.loading {
      padding: 60px;
    }

    &.slim {
      padding: 16px 24px;
    }

    &.nopad {
      padding: 0;

      &:not(:last-child) {
        border-bottom: none;
      }

      .loading {
        padding: 14px 24px;
        color: $textSecondary;
        font-size: 14px;
      }
    }

    .cc-form {
      width: 375px;

      button {
        margin: 15px 0 0;
      }

      .input-error {
        font-size: 13px;
        margin-top: 6px;
        color: $error;
      }
    }

    .cc-content {
      font-size: 14px;
      line-height: 1.4em;
    }

    .cc-usage-bars {
      margin-top: 20px;
    }
  }

  .cc-footer {
    padding: 10px;
    margin-top: auto;
    text-align: center;

    a {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
