@import "../../assets/utils/vars.scss";

.no-tasks-found {
  font-size: 15px;
  font-weight: 400;
  color: $inputPlaceholder;
  line-height: 1em;
  margin: 0;
}

.task-display-toggle-wrap {
  display: flex;
  align-items: center;
  margin-left: 24px;

  .task-display-toggle {
    padding: 8px 10px 6px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s all;

    svg {
      height: auto;
      width: 14px;

      path {
        fill: $inputPlaceholder;
        transition: 0.2s all;
      }
    }

    &:hover,
    &.is-active {
      background: $infoBG;

      svg {
        path {
          fill: $infoFG;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
