@import "../../../assets/utils/vars.scss";

.timeline-content-card {
  position: relative;
  z-index: 2;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(189, 189, 189, 0.45);
  border-top: 4px solid #fff;
  transition: 0.2s all;
  left: 0;
  user-select: none;

  &:hover {
    left: 10px;
    border-top: 4px solid $info;

    .content-image {
      .c-image-wrap {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  &.loading {
    height: 100px;
  }

  .content-head {
    position: relative;
    padding-left: 75px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e3e6f0;

    .action-type-wrap {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      background: $info;
      left: 30px;
      top: -25px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .remove-content {
      display: block;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      color: #80889d;
      transition: 0.2s all;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background: rgba(234, 68, 68, 0.1);

        svg {
          path {
            fill: #ea4444;
          }
        }
      }

      svg {
        path {
          fill: #80889d;
          transition: 0.2s all;
        }
      }
    }

    h1 {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 10px 0 9px;
    }

    h2 {
      margin-left: auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2em;
      color: #80889d;
      padding: 10px 0 9px;
    }

    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2em;
      color: #80889d;
      flex: 0 0 60px;
      text-align: center;
      margin-right: 10px;
      padding: 10px 0 9px;
    }
  }

  .content-image {
    padding: 5px;
    border-bottom: 1px solid #e3e6f0;
    display: flex;

    .c-image-wrap {
      flex: 0 0 30%;
      position: relative;
      width: 100%;
      height: 195px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;

      &.multiple {
        .c-image-inner {
          canvas,
          img {
            filter: blur(4px);
          }
        }
      }

      &:not(.multiple) {
        &:after {
          content: "";
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000;
          opacity: 0.1;
          transition: 0.2s all;
        }
      }

      .c-image-inner {
        position: relative;
        height: 100%;
        width: 100%;
        flex: 1 1;

        img {
          position: relative;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .react-pdf__Document,
        .react-pdf__Page {
          position: relative;
          height: 100%;
          width: 100%;

          canvas {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
          }
        }

        .thumbnail-pending {
          display: block;
          margin: auto 0;
          font-size: 14px;
          line-height: 195px;
          text-align: center;
          color: $textSecondary;
        }
      }

      .c-file-count {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.8);

        p {
          color: $textSecondary;
          font-size: 65px;
          font-weight: 600;
          line-height: 1em;
        }

        small {
          color: $textSecondary;
          font-size: 14px;
          line-height: 1em;
        }
      }
    }

    .content-body {
      flex: 1 1 auto;
      padding: 15px 15px 15px 25px;

      .content-stat {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        h4 {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.2em;
          color: rgba(128, 136, 157, 0.6);
        }

        h5 {
          font-weight: 500;
          font-size: 15px;
          line-height: 1.2em;
          color: #80889d;

          span {
            cursor: pointer;
            color: $infoFG;
            transition: 0.2s all;

            &:hover {
              color: darken($infoFG, 5%);
            }
          }
        }
      }
    }
  }

  .content-footer {
    padding: 10px;
    display: flex;
    justify-content: center;

    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .content-footer-list {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s all;
    padding: 0 10px 0;

    &.is-open {
      max-height: 999px;
      padding: 0 10px 10px;
    }

    .content-footer-list-file {
      display: flex;
      align-self: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .cfl-image {
        flex: 0 0;

        .cfl-image-wrap {
          position: relative;
          display: block;
          height: 50px;
          width: 50px;

          .react-pdf__Document,
          .react-pdf__Page {
            position: relative;
            height: 100%;
            width: 100%;
          }

          canvas,
          img {
            display: block !important;
            position: relative !important;
            height: 100% !important;
            width: 100% !important;
            object-fit: cover !important;
          }
        }
      }

      .cfl-text {
        flex: 1 1;
        display: flex;
        margin: 0 25px;
        justify-content: center;
        flex-direction: column;

        p {
          font-size: 14px;
          padding-bottom: 2px;
          line-height: 1.2em;
          color: $textPrimary;
        }

        small {
          font-weight: 500;
          font-size: 14px;
          line-height: 1em;
          color: $textSecondary;
        }
      }

      .cfl-actions {
        flex: 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &.add-content {
    position: relative;
    padding: 20px;
    align-items: center;
    padding: 25px;

    .add-content-form {
      width: 100%;

      button {
        margin-top: 0;
      }

      .inputfield {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    button {
      width: 100%;
    }

    &.true {
      display: flex;
      justify-content: center;
      flex-direction: column;
      cursor: default;
    }

    .cancel-add {
      margin-top: 5px;
    }

    .add-content-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        cursor: pointer;
        font-size: 40px;
        transition: 0.2s all;
        margin-right: 10px;

        path {
          fill: #ddd;
          transition: 0.2s all;
        }
      }

      &:hover {
        svg {
          path {
            fill: #c3c3c3;
          }
        }
      }

      h1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2em;
        color: #80889d;
      }
    }
  }
}
