@import "../../../../assets/utils/vars.scss";

.ui-radio-button {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    .ui-radio-button-box {
        position: relative;
        flex: 0 0 15px;
        margin-right: 10px;
        width: 15px;
        height: 15px;
        background: #fff;
        border: 1px solid $inputBorder;
        border-radius: 100%;
        transition: 0.2s all;

        &:after {
            content: "";
            position: absolute;
            height: 60%;
            width: 60%;
            background: #fff;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, -50%) scale(0.75);
        }

        &.is-checked {
            background: $primary;
            border: 1px solid $primary;

            &:after {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }

    label {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        white-space: nowrap;
        color: $label;
    }
}