.continue-splash {
  padding: 20px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #eff2f5;

  .continue-box {
    background: #fff;
    border-radius: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    text-align: center;

    img {
      max-width: 235px;
    }

    p {
      font-size: 15px;
      font-weight: 500;
      margin: 20px 0 10px;
      line-height: 1.45em;
    }

    small {
      display: block;
      font-size: 12px;
      opacity: 0.6;
      line-height: 1.45em;
    }
  }
}
