@import "../../assets/utils/vars.scss";

.dashboard-tiles {
  padding: 20px 20px 0 20px;
  width: 100%;

  .dashboard-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .recent-projects {
    margin-bottom: 40px;
  }

  .dashboard-statistics {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;

    .upper-row {
      display: grid;
      grid-gap: 25px;
      grid-template-columns: repeat(5, 1fr);
    }

    .lower-row {
      display: grid;
      grid-gap: 25px;
      grid-template-columns: 1fr 2fr 1fr;
    }

    .statistic-card {
      background: #fff;
      border-radius: 24px;
      overflow: hidden;
      padding: 24px 32px;

      h6 {
        font-size: 17px;
        line-height: 1em;
        margin-bottom: 14px;
      }

      h5 {
        display: flex;
        align-items: baseline;

        span {
          font-size: 50px;
        }

        small {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-left: 8px;

          svg {
            margin-right: 2px;
          }

          &.NEUTRAL {
            color: $inputPlaceholder;

            svg {
              path {
                fill: $inputPlaceholder;
              }
            }
          }

          &.POSITIVE {
            color: $positiveFG;

            svg {
              path {
                fill: $positiveFG;
              }
            }
          }

          &.NEGATIVE {
            color: $negativeFG;

            svg {
              path {
                fill: $negativeFG;
              }
            }
          }
        }
      }
    }
  }
}
