@import "../../../../assets/utils/vars.scss";

.tile-title {
  position: relative;
  margin-bottom: 25px;

  &.has-border {
    padding-bottom: 14px;
    border-bottom: 1px solid $inputBorder;
  }

  &.is-flex {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1em;
    color: $darkfont;

    svg {
      height: 12px;
      width: 10px;
      margin: 0 12px;

      path {
        fill: $darkfont;
      }
    }

    a {
      position: relative;
      font-weight: 500;
      color: $infoFG;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        background: $infoFG;
      }
    }
  }

  p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: $inputPlaceholder;
    line-height: 1.25em;
  }
}
