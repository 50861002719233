.content-feedback-tile {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: crosshair;
  transition: 0.2s all;

  &.is-active {
    z-index: 101;
  }

  &:not(.is-active) {
    opacity: 0;
  }

  &.pen {
    cursor: none;
  }

  .content-feedback-click-zone {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
