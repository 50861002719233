@import "../../../../assets/utils/vars.scss";

.ui-select-wrapper {
  position: relative;

  &.has-error {
    .ui-select-upper {
      border: 1px solid red;
    }

    small {
      color: red;
    }
  }

  .ui-input-upper-placeholder {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 500;
    color: $inputPlaceholder;
    transition: 0.25s all;
    opacity: 0;
    padding: 3px 6px;
    pointer-events: none;

    &.active {
      top: 0px;
      left: 14px;
      opacity: 1;
      font-size: 12px;
      background: #fff;
    }
  }

  .ui-select-upper {
    transition: 0.2s all;
    min-height: 39px;
    cursor: pointer;

    &:not(.no-styles) {
      position: relative;
      padding: 9px 18px;
      background: $inputBackground;
      border: 1px solid $inputBorder;
      border-radius: 5px;
    }

    &.dropdown-showing {
      border-radius: 5px 5px 0 0;

      .ui-select-icon {
        svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &.has-value {
      &:not(.no-styles) {
        background: $inputBackgroundActive;
        border: 1px solid $inputBorderActive;

        .ui-select-placeholder {
          color: $label;
        }
      }

      .ui-input-wrapper {
        .ui-input-block {
          .ui-input-text {
            border: 1px solid $inputBorderActive !important;
          }
        }
      }
    }

    .ui-input-wrapper {
      .ui-input-block {
        .ui-input-text {
          &.dropdown-showing {
            border-radius: 5px 5px 0 0;
          }

          &:focus,
          &:active,
          &:not(:placeholder-shown) {
            border: 1px solid $inputBorder;
          }
        }
      }
    }

    .ui-select-placeholder {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: $inputPlaceholder;
      min-height: 19px;

      .ui-multiple-selected-options-row {
        position: absolute;
        z-index: 2;
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        align-items: center;

        .ui-multiple-selected-option {
          color: #5d6373;
          background: #e7e7e7;
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 12px;
          border: 1px solid #d5d5d5;
          transition: 0.2s all;
          white-space: nowrap;

          &.is-hidden {
            opacity: 0;
            pointer-events: none;
            position: absolute;
          }

          &:hover {
            background: darken(#e7e7e7, 5%);
          }

          &:not(:last-child) {
            margin-right: 5px;
          }

          &:not(.is-total) {
            &:after {
              content: "x";
              padding: 3px 0 0 5px;
              margin-left: 5px;
              border-left: 1px solid #d5d5d5;
            }
          }
        }
      }
    }

    .ui-select-icon {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);

      svg {
        position: absolute;
        top: 50%;
        height: 10px;
        transform: translateY(-50%);
        transition: 0.2s all;
      }
    }
  }

  .ui-select-dropdown {
    position: absolute;
    z-index: 1005;
    border-radius: 0 0 5px 5px;
    background: #fff;
    max-height: 0;
    overflow: hidden;
    top: 100%;
    width: 100%;

    &.is-showing {
      max-height: 160px;
      overflow-y: auto;
      border-left: 1px solid $inputBorder;
      border-right: 1px solid $inputBorder;
      border-bottom: 1px solid $inputBorder;
    }

    .ui-multiple-select-option {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 18px;

      .ui-checkbox {
        width: 100%;
      }
    }

    .ui-select-option {
      font-size: 14px;
      font-weight: 400;
      color: $label;
      padding: 8px 18px;
      cursor: pointer;
      background: #fff;
      transition: 0.2s all;
      position: relative;

      &.is-active,
      &:hover {
        color: #fff;
        background: $primary;
      }

      &.is-locked {
        cursor: default;
        color: #bdbdbf;
        background: #f6f7f7;
        user-select: none;
        pointer-events: none;

        &:hover {
          color: #bdbdbf;
          background: #f6f7f7;
        }

        .premium-flag {
          position: absolute;
          z-index: 2;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;

          p {
            font-size: 11px;
            font-weight: 600;
            color: $primary;
            line-height: 1em;
            margin: 0 10px 0;
            font-style: italic;
          }

          svg {
            height: 14px;
            width: auto;

            path {
              fill: #ffd700;
            }
          }
        }
      }

      &.none-available {
        user-select: none;
        font-size: 12px;
        color: $inputPlaceholder;
        background: #fff;
        cursor: default;

        &:hover {
          color: $inputPlaceholder;
          background: #fff;
        }
      }
    }
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    margin: 5px 0 0;
    color: $inputPlaceholder;
  }
}
